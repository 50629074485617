// routes
// config
// components
import Iconify from 'src/components/iconify';
import { useTranslation } from 'react-i18next';
import { getPathWithLang, paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const NavConfig = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  return [
    {
      title: t('Create'),
      icon: <Iconify icon="solar:home-2-bold-duotone" />,
      path: getPathWithLang(paths.app.create, currentLang),
    },
    {
      title: t('Explore'),
      icon: <Iconify icon="solar:home-2-bold-duotone" />,
      path: '/',
    },
    {
      title: t('About us'),
      icon: <Iconify icon="solar:home-2-bold-duotone" />,
      path: '/',
    },
    {
      title: t('Contact us'),
      icon: <Iconify icon="solar:home-2-bold-duotone" />,
      path: '/',
    },
  ];
};