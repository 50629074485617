import { RouteLeg } from "src/types"

export const TRIP = {
    "title": "3-Day Cultural Family Trip in Tokyo on a Budget",
    "trip_type": "family",
    "budget_type": "low",
    "trip_pace": "slow",
    "interest": "culture",
    "tips": [
        "Consider purchasing a Japan Rail Pass if you plan to travel outside of Tokyo.",
        "Bring comfortable walking shoes, as you'll be doing a lot of exploring.",
        "Learn some basic Japanese phrases to enhance your experience.",
        "Take advantage of free activities like visiting temples and shrines.",
        "Pack snacks and drinks to save money on meals.",
        "Look for deals on souvenirs and local crafts."
    ],
    "days": [
        {
            "day": 1,
            "date": "2024-10-10",
            "activities": [
                {
                    "place": "Senso-ji Temple",
                    "placeId": "ChIJgz_22f-o4gARx-9e_F84W2c",
                    "coordinates": {
                        "lat": 35.7147651,
                        "lng": 139.7966553
                    },
                    "address": "2 Chome-3-1 Asakusa, Taito City, Tokyo 111-0032, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DOrExhWaYvgE0i3ykGGGvz9JSF6ZNi0fShz3J8MzI81AraG0X0P6RO0gf_m0rTmOa0U8WCeSGp9o_rXqmbh2dw6toqPUIYo0BgD-MBuJX_PPgOqW3CHJeikWGmSkCBbilX80RyF13RK9o6a_JEn8YJOlgAzcUhyDOC2ewQUz3idFXD-&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Visit Tokyo's oldest temple, Senso-ji, and explore its vibrant Nakamise-dori shopping street filled with traditional crafts and snacks."
                },
                {
                    "place": "Tokyo National Museum",
                    "placeId": "ChIJ0-35V4-o4gARV60W_604_Q",
                    "coordinates": {
                        "lat": 35.7188351,
                        "lng": 139.7765215
                    },
                    "address": "13-9 Uenokoen, Taito City, Tokyo 110-8712, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DOkEfuuNdZ0fEV5SJRwFogZqInStl5PwoszxjR5V-87CXrwNf9G718yjCV5JIlip__kNYVLWbfE710UN9nrcpUnK2NskU-LN1VFPE7zpmPWBh4qRZ7qt6zr1MhNQpEIPFtCZo3pqVmpsMk_KvvpfXCqXa_ZNAMgrcLum3AajHY3yY58&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Immerse yourselves in Japanese art and history at the Tokyo National Museum, home to a vast collection of artifacts."
                },
                {
                    "place": "Ueno Park",
                    "placeId": "ChIJr4h6Y4-o4gARy0L3Y-w29Q",
                    "coordinates": {
                        "lat": 35.7147557,
                        "lng": 139.7734312
                    },
                    "address": "Uenokoen, Taito City, Tokyo 110-0007, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DN0bn-Da107T-C_LKOrFXH1weExRL5of_SlI3qxc7GpDqmTYh13Flr89-Knb5QnatjSFxYi7Em81KbWgPlmlZNTSZqdGXf8oExvU2iz-YRE1hotZev6jEqv7Cjl9FMpJP933NfvpqRiCzzdRpAfgGUk2e4o85VGFrCmYf0TkssBuWH0&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Take a leisurely stroll through Ueno Park, a vast green space with museums, shrines, and the Tokyo Metropolitan Art Museum."
                }
            ]
        },
        {
            "day": 2,
            "date": "2024-10-11",
            "activities": [
                {
                    "place": "Meiji Jingu Shrine",
                    "placeId": "ChIJz38-9V-o4gAR80-50w962Q",
                    "coordinates": {
                        "lat": 35.6763976,
                        "lng": 139.6993259
                    },
                    "address": "1-1 Yoyogikamizonocho, Shibuya City, Tokyo 151-8557, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DPT6rAeibHi7mTt6Hp-ZFtPpthsF3n8J4AsMWC9F7IN-1cVGwwvFUAaWIkIz1LbXf58zgL3gnVNiWfjSCbWpp4je3UA6LHcdlhHHqBBNQkR6NMWSHC6thZVrMwW6Q-stOkqXufl2MGRJxbTM6TGFvWEjHu-t3iXtM-0veJwKYZcgtkK&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Visit Meiji Jingu Shrine, a serene oasis dedicated to Emperor Meiji and Empress Shoken, surrounded by lush forest."
                },
                {
                    "place": "Harajuku",
                    "placeId": "ChIJn7b-5V-o4gARq_68_p339Q",
                    "coordinates": {
                        "lat": 35.6699682,
                        "lng": 139.709008
                    },
                    "address": "Harajuku, Jingumae, Shibuya City, Tokyo 150-0001, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DMfKmO89Uy160SaBsBvDx8GLVyZT1hIRE1qYmMvaiB-Ju8Jhxty089RyIbvwK5U5glNBfZ6TzV5xPWRgGn88CdaDDoCLWvjNOpMshQNAY85J2wfJJ_2vC7dcwzvCAgwRcgudGn17bW54lqKiMOIhY2hPT5cLaRbuLV_GnFeZJJQJAi8&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Explore the vibrant and trendy district of Harajuku, known for its unique street fashion and youth culture."
                },
                {
                    "place": "Yoyogi Park",
                    "placeId": "ChIJQ5k-5V-o4gAR293o-81aDQ",
                    "coordinates": {
                        "lat": 35.6700649,
                        "lng": 139.6949656
                    },
                    "address": "2-1 Yoyogikamizonocho, Shibuya City, Tokyo 151-0052, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DMqlRBQdUMH6unN6sOIu_7XjjLNLiEbud-m3vaY3YiNksavdpf5W5uFPIDwrAANrlMNT_RPL_KBImhBibgNpj2lcef9X4HpDfMH-TilDPSM680A4fY2YVO5fBb2oLYRvelvLafubZNtdmo5nmzhhCcvmugBH2itjlam7jfSONx-sk9X&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Relax and enjoy the greenery of Yoyogi Park, a popular spot for picnics, concerts, and street performers."
                }
            ]
        },
        {
            "day": 3,
            "date": "2024-10-12",
            "activities": [
                {
                    "place": "Tokyo Skytree",
                    "placeId": "ChIJv3H1jV-o4gAR4X8Q358r7Q",
                    "coordinates": {
                        "lat": 35.7100627,
                        "lng": 139.8107004
                    },
                    "address": "1 Chome-1-2 Oshiage, Sumida City, Tokyo 131-0045, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DMcC3G-sSTpN_Wsdc7bwTcvU6lM2vuIClbVOBvJ_S34oHbu-V8y3Qn--SGCNdEu1a4xg92UTrloAW9k4JpzLhcbnv4StslBIRIBJsu2qXaM_OGb6d-484zqjYbkZL1_XENEF5JzwWN5XaI-SPr5uslrsXEZcX_IfaH9my95ZBlF7QaK&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Ascend to the top of Tokyo Skytree, the tallest structure in Japan, for panoramic views of the city."
                },
                {
                    "place": "Sumida River",
                    "placeId": "ChIJ-w844V-o4gARf_J-5_D_Q",
                    "coordinates": {
                        "lat": 35.7084279,
                        "lng": 139.7967265
                    },
                    "address": "Sumida River, Tokyo, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DPOO9BNNfKLxzfn2XHM8WPtegY6hazzL1Pqh0Rh39Wf8QywiL_HSC9RwabuKO9dQ9HG6nD6LCix0lSnyE0DknNfe3mo0nEhRMUpQhpK6s06UkGSmbBX2ltikY6E7kP33h9D7-w6vQCgBH4kix3mwG3yMap_lVPQ5UChaeTK2T2Op8BE&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Take a relaxing boat cruise along the Sumida River, passing by historical landmarks and bridges."
                },
                {
                    "place": "Tokyo National Museum",
                    "placeId": "ChIJ0-35V4-o4gARV60W_604_Q",
                    "coordinates": {
                        "lat": 35.7188351,
                        "lng": 139.7765215
                    },
                    "address": "13-9 Uenokoen, Taito City, Tokyo 110-8712, Japan",
                    "imageUrl": "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=AdCG2DOkEfuuNdZ0fEV5SJRwFogZqInStl5PwoszxjR5V-87CXrwNf9G718yjCV5JIlip__kNYVLWbfE710UN9nrcpUnK2NskU-LN1VFPE7zpmPWBh4qRZ7qt6zr1MhNQpEIPFtCZo3pqVmpsMk_KvvpfXCqXa_ZNAMgrcLum3AajHY3yY58&key=AIzaSyBghTk3vO9CynUm7a2MVSV8_zffvBnSCII",
                    "description": "Explore the Tokyo National Museum again for a more in-depth look at its diverse collections, including Japanese art, history, and culture."
                }
            ]
        }
    ]
}

export const ROUTES: RouteLeg[] = [
    {
        "distance": {
            "text": "2.6 km",
            "value": 2588
        },
        "duration": {
            "text": "9 mins",
            "value": 537
        }
    },
    {
        "distance": {
            "text": "1.9 km",
            "value": 1935
        },
        "duration": {
            "text": "7 mins",
            "value": 400
        },
    },
    {
        "distance": {
            "text": "1.9 km",
            "value": 1935
        },
        "duration": {
            "text": "7 mins",
            "value": 400
        },
    }
]