import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

// Import your translation files
import enTranslation from './locales/en/translation.json';
import jaTranslation from './locales/ja/translation.json';
import viTranslation from './locales/vi/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: enTranslation },
      ja: { translation: jaTranslation },
      vi: { translation: viTranslation },
    },
    // lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
